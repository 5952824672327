.btn-primary {
  padding: 0rem 2.5rem;
  background: transparent;
  border: none;
  height: var(--button-height);
  display: inline-flex;
  justify-content: center;
  min-width: 13rem;
  align-items: center;
  position: relative;
  background: var(--theme-color);
  /* letter-spacing: 0.2rem; */
  color: var(--foreground);
  /* text-transform: uppercase; */
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  margin-right: 1rem;
  z-index: 2;
  margin-bottom: 2rem;
  white-space: nowrap;
  font-size: 2rem;
  border-radius: var(--radius);
  transition: all 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}

.btn-primary .fas {
  font-size: 2rem;
}

.jumbotron-action .btn-primary .fas {
  margin-right: 1rem;
  font-size: 1.8rem;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-chevron {
  position: absolute;
  right: 01rem;
  top: 1rem;
  background: transparent;
  color: var(--font-color);
  border: none;
  -webkit-tap-highlight-color: transparent;
  padding: 1rem;
  z-index: 1;
}

.btn-circle {
  width: var(--button-height) !important;
  min-width: unset;
  height: var(--button-height) !important;
  border-radius: 50%;
  padding: 0 !important;
  border: 1px solid var(--border-color) !important;
}

h1>button {
  position: relative;
  top: -5px;
}


.btn-small {
  padding: 0 2rem;
  font-size: 1.4rem;
  height: 5rem !important;
  min-width: 10rem;
  border-radius: var(--small-radius);
}

.btn-primary>span:first-child {
  margin-left: 0;
  margin-right: -0.5rem;
  display: flex;
  align-items: center;
  color: inherit !important;
  justify-content: center;
  width: 100%;
}

.btn-primary>span:last-child {
  margin-right: 0rem;
}

/* .btn-primary:focus {
  color: var(--font-color);
} */
.btn-accent {
  /* color: var(--font-color); */
  background-color: transparent;
  background-image: none;
  color: var(--font-color);
  /* font-weight: bold; */
  height: var(--button-height);
  margin-bottom: 2rem;
  border: 1px solid var(--font-color);
}

.btn-accent.btn-accent-alt {
  color: var(--background);
}

.btn-primary.extra-pad {
  padding: 0rem 4rem;
}

.btn-colored {
  background: var(--theme-color);
  color: var(--foreground);
  border-color: var(--theme-color);
}

.btn-accent:focus,
.btn-accent:active {
  box-shadow: none !important;
}

button:focus {
  outline: none;
}

.btn-disabled,
.v2-product-page-container .actions button.btn-disabled:last-child {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
  background-color: var(--btn-disabled) !important;
  color: var(--font-color);
}

.v2-product-page-container .actions button.btn-disabled:last-child {
  border-color: transparent !important;
}

.btn-no-hover:hover {
  box-shadow: none;
}

a {
  color: var(--font-color);
}

.btn-white-font {
  color: var(--white);
}

.btn-white {
  background: var(--background);
  color: var(--font-color);
}

.btn-white:focus {
  color: #111;
}

.btn-big {
  padding: 3.5rem 3rem !important;
  height: var(--button-height);
  font-size: 1.4rem;
  min-width: 100px !important;
}

.btn-round {
  border-radius: 55px;
}

.btn-full-round {
  border-radius: 40px !important;
}

.btn-themed {
  background: var(--background);
  color: var(--header-color);
}

.navbar.navbar-top .btn-round {
  border-radius: var(--radius);
}

.btn-mar-right {
  margin-right: 1rem;
}

.no-mar-right {
  margin-right: 0;
}

.btn-primary .fa-check-circle {
  font-size: 2rem;
  margin-right: 1rem;
}

.fileContainer p {
  width: 100%;
  text-align: center;
}

.fileContainer .uploadIcon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top: 2rem;
}

.fileUploader>.fileContainer {
  background-color: var(--background) !important;
  align-items: flex-start;
  box-shadow: none;
  border-radius: var(--radius);
  padding: 4rem 6rem;
  box-sizing: border-box;
}

.fileUploader>.fileContainer .chooseFileButton {
  background: var(--border-color) !important;
  color: var(--font-color) !important;
  font-family: var(--font-one) !important;
  border-radius: var(--radius);
  font-weight: normal;
  height: 6rem;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

button {
  font-family: var(--font-one), sans-serif;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.btn-very-small {
  height: 2.8rem;
  margin-left: 1rem !important;
  font-size: 1rem;
  padding: 0 1.2rem;
  box-shadow: none;
  border: 1px solid;
  margin-top: 0 !important;
}

.btn-no-shadow,
.btn-no-shadow:hover,
.btn-no-shadow:focus {
  box-shadow: none !important;
}

.btn-danger,
.btn-danger:hover {
  color: var(--white);
  border-color: var(--error-font-color);
  box-shadow: none;
  background-color: var(--error-font-color);
}

.btn-alt,
.btn-alt:hover {
  color: var(--theme-color);
  background: var(--background);
}

/* .btn-primary:last-child { */
/* margin-right: 0; */
/* } */
.btn-primary {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

button div {
  background-color: var(--theme-color) !important;
  border-radius: 50%;
  color: var(--white) !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  margin-left: 2rem;
  font-size: 2rem;
  margin-right: 5px;
}

.btn-link {
  padding: 0 !important;
  min-width: auto !important;
  height: 3rem !important;
  color: var(--font-color) !important;
  background: none !important;
  display: inline-flex !important;
  width: auto !important;
  box-shadow: none !important;
  margin-right: 4rem !important;
}

.simple-link {
  text-decoration: underline !important;
  font-weight: bold;
}

.btn-link:hover {
  text-decoration: underline !important;
}

button .lnr {
  font-size: 3rem !important;
}

.button-active-tab {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--theme-color);
  border-radius: var(--radius);
}

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--font-color);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--background);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--background);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

/* .has-check {
} */

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--background);
  }
}

.no-full-on-mobile {
  width: auto !important;
  min-width: 9rem !important;
}

@media only screen and (max-width: 766px) {
  .btn-primary .fas {
    margin-right: 0.5rem;
  }

  .btn-primary {
    /* width: 100%; */
    height: 7rem;
    padding: 0 3rem;
    min-width: auto;
    font-size: 2rem;
    margin-top: 1rem !important;
  }

  .btn-small {
    width: auto;
    height: 6rem !important;
  }

  .btn-circle {
    font-size: 2rem;
    margin-top: 0 !important;
  }

  .btn-very-small {
    height: 2.4rem;
  }

  *button[class="btn-primary"]:hover {
    box-shadow: initial !important;
  }
}