* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-size: 1.6rem;
}
