.input-range {
  height: 1rem;
  position: relative;
  width: 95% !important;
  left: 5% !important;
  margin-top: 5rem;
  margin-bottom: 5rem !important;
}

.sticky-filter {
    position: sticky;
    top: 10rem;
}

@media only screen and (max-width: 768px) {
    .input-range {
        height: 1rem;
        position: relative;
        width: 84% !important;
        left: 5% !important;
        margin-top: 5rem;
        margin-bottom: 5rem !important;
      }
}