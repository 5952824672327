.navigation-container {
  padding: 1rem;
  position: sticky;
  top: 0rem;
  z-index: 100;
  background-color: var(--background);
  padding-bottom: 0;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--foreground);
  padding: 0 2rem;
  border-radius: var(--radius);
  box-shadow: none;
  transition: var(--transition);
}

.navigation-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.navigation-logo {
  background-color: var(--background);
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  width: 6rem;
  height: 6rem;
  justify-content: center;
}

.navigation-logo img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: contain;
}

.page-header {
  font-size: 2rem;
  color: var(--theme-color);
  font-weight: bolder;
  font-family: var(--font-two);
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.center-text {
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 0.3rem;
  font-weight: bolder;
}

.navigation-right-content {
  display: flex;
  align-items: center;
}

.navigation-right-content>.icon-button:last-child {
  margin-right: 0;
}

.middle-content {
  position: relative;
  right: -5%;
}

.nav-header {
  /* height: 60px; */
  display: flex;
  padding: 2.5rem 2rem;
  box-sizing: border-box;
  align-items: center;
  backdrop-filter: blur(20px);
  background-color: var(--background);
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  transition: all 1s ease;
  padding-left: 1.5rem;
}

.nav-header .btn-circle {
  margin-left: 0;
}

.nav-header .flex {
  margin: 0;
}

a {
  text-decoration: none;
}

.nav-header .input-field {
  height: 7rem;
  font-size: 2rem;
  background: var(--background);
  border: 1px solid transparent;
  margin-bottom: 3rem !important;
  z-index: 2;
  border-radius: var(--radius);
}

.nav-header .input-field input {
  font-size: 2rem;
  box-shadow: none;
}

.nav-header .input-field input::placeholder {
  color: var(--font-color);
}

.nav-header .input-field .btn-primary {
  height: 5.5rem;
  margin-right: 0.9rem !important;
  border-radius: var(--radius);
  background: transparent;
  color: var(--header-color);
  width: auto !important;
  min-width: auto;
  padding: 2rem;
}

.seller-tag {
  display: block;
  padding: 0.7rem 1.5rem;
  background-color: var(--theme-color);
  color: var(--background);
  border-radius: var(--radius);
  margin-left: 1rem;
}

.floating-logo {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0rem;
  z-index: 100;
  /* width: 10%; */
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  background: linear-gradient(294deg, #1a2026, #131313);
}

.header__logo {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  margin: 0 0px;
}

.theme-light .header__logo {
  filter: invert(1);
}

.header__search-container {
  max-width: 630px;
  width: 100%;
}

.header__search-container .fa-times {
  width: 7rem !important;
  height: 7rem;
  margin-top: 0rem;
  border-radius: 50%;
  color: var(--font-color);
  margin-left: 2rem;
  font-size: 3rem;
}

.header__search {
  display: flex;
  position: relative;
  flex: 1;
  height: 5rem;
  align-items: center;
  max-width: 360px;
  width: 100%;
  border-radius: var(--radius);
  transition: all 0.5s ease;
}

.header__search:focus-within {}

.header__searchInput {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
}

.header__searchIcon {
  padding: 5px;
  height: 22px !important;
}

.header__optionLineOne {
  font-size: 10px;
}

.header__optionLineTwo {}

.header__optionBasket {
  display: flex;
  position: relative;
  align-items: center;
  /* color: white; */
  font-size: 12px;
  color: (--theme-color);
}

.header__basketCount {
  margin-left: 4px;
  margin-right: 4px;
  /* position: absolute;
    right: -50%;
    top: -100%; */
  color: (--theme-color);
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.lnr {
  font-size: 2.4rem;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  /* color: white; */
}

.icon-flex {
  display: flex;
  font-size: 2rem;
}

.icon-flex-mobile {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.icon-flex a {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-right: 0rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  transition: var(--transition);
}

.icon-flex a:hover {
  border: 1px solid var(--font-color);
}

.nav-header .search-toggle {
  opacity: 1;
}

.nav-header a {}

.icon-flex button {
  margin-bottom: 0;
}

.nav-menu-pane {
  position: fixed;
  right: 0;
  top: 0;
  min-height: 100vh;
  z-index: 200;
  width: 400px;
  transform: translateX(150%);
  background-color: var(--foreground);
  transition: all 0.6s ease;
  box-shadow: -2rem 0 4rem var(--shadow-color);
}

.nav-menu-pane.menu-open {
  transform: translateX(0%);
}

.nav-menu-pane>div {
  width: 100% !important;
  justify-content: space-between;
  padding: 2rem;
}

.nmp-links {
  padding: 5vh !important;
  text-align: right;
  padding-top: 18vh !important;
  padding: 5vh !important;
  text-align: right;
  padding-top: 18vh !important;
  display: flex;
  flex-direction: column;
}

.nmp-links a {
  font-family: var(--font-one);
  font-size: 2.5rem;
  margin-top: 2rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease;
}

.nmp-links a:hover {
  /* filter: brightness(200%); */
  opacity: 0.7;
}

.nmp-links a:last-child {
  border-bottom: none;
}

.nmp-links a .lnr {
  margin-right: 1rem;
}

.nav-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-header * {
  transition: all 0.4s ease;
}

.nav-item {
  width: 6rem;
  height: 6rem;
  margin: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.nav-item .nav-item-icon.fas {
  font-size: 2.6rem;
}

.nav-item-active .nav-item-icon.fas {
  font-size: 2.6rem;
}

.nav-item-tag {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  top: 0;
  right: 0;
  font-size: 1.2rem;
  transform: translateY(-40%);
}

.nav-item-label {
  margin-top: 1rem;
  font-weight: bold;
}

.nav-item-active {
  color: var(--theme-color);
}

.highlighed {
  background: var(--theme-color) !important;
  color: var(--background) !important;
  margin-left: 0 !important;
  margin-right: 2rem !important;
}

.nav-header .nav-item:last-child {
  margin-right: 0;
}

.nav-header .nav-item:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 766px) {
  .navigation-container {
    padding-top: 1rem !important;
    position: fixed;
    left: 1rem;
    top: 0;
    width: calc(100% - 2rem);
  }

  .nav-item-label {
    margin-top: 1rem;
    font-weight: bold;
    display: none;
  }

  .nav-menu-pane {
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }

  .nav-header {
    height: 12rem;
    background-color: var(--theme-color);
    background: linear-gradient(294deg, #1a2026, #131313);
  }

  .nav-header .nav-item {
    color: #aaa;
  }

  .nav-header .nav-item-active {
    color: white;
  }

  .nav-item-active .nav-item-icon.fas {
    font-size: 3.5rem !important;
  }

  .nav-item .nav-item-icon.fas {
    font-size: 2.8rem;
  }

  .header__nav {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .nav-back-button {
    position: absolute;
    top: -3rem;
    transform: translateY(-120%);
    left: 2rem;
    box-shadow: 0 0.5rem 2rem var(--shadow-color);
    border-radius: var(--radius);
    background-color: var(--theme-color);
  }

  .nav-back-button a {
    width: 8rem;
    font-size: 5rem;
    height: 8rem;
  }

  .nav-back-button a span.fas {
    font-size: 3rem;
    color: var(--background);
  }

  .nav-header .input-field {
    box-shadow: none;
  }

  .nav-header {
    /* box-shadow: 0 0 1rem var(--shadow-color); */
    padding: 2rem 3rem;
    position: fixed;
    bottom: 0;
    left: 0;
    top: unset;
    width: 100%;
    z-index: 111;
  }

  .header__logo {
    width: 5rem;
    object-fit: contain;
    margin: 0 0rem;
    filter: none;
  }

  .btw {
    width: 100%;
    justify-content: space-between;
    margin: 0 1rem;
  }

  .nav-header .header__search {
    /* display: none; */
    width: 90%;
    max-width: 90vw;
  }

  .nav-header .header__search .btn-primary {
    width: 9rem;
  }

  .nav-header .search-toggle {
    opacity: 1;
    display: flex;
  }

  /* .icon-flex a .lnr {
    font-size: 2rem !important;
  } */
  .icon-flex a {
    margin-right: 0 !important;
    font-size: 3rem;
  }

  .icon-flex-mobile {
    display: flex;
    justify-content: center;
    font-size: 30px;
  }
}