.career-header-text {
    font-weight: normal;
    font-size: 6rem;
    color: var(--header-color);
    font-family: var(--font-two) !important;
}

.career-header-text span {
    font-weight: bold;
    font-family: var(--font-two) !important;
}

.career-subheader-text {
    font-size: 20px;
}

.join-reason-header {
    font-weight: normal;
    font-size: 6rem;
    color: var(--header-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    text-align: center;
    font-family: var(--font-two) !important;
}

.join-reason-header>.join-reason-header-text {
    display: flex;
    align-items: center;
    font-size: 6rem;
    font-weight: normal;
    font-family: var(--font-two) !important;
}

.join-reason-header>.join-reason-header-text>span {
    font-weight: bold;
    margin-left: 15px;
    font-size: 6rem;
    font-family: var(--font-two) !important;
}

.join-reason-header>p {
    font-size: 20px;
    margin-top: 1rem;
}

#open-positions.highlight {
    margin-top: 10rem;
    transition: margin-top 0.3s ease-in-out;
}

@media only screen and (max-width: 766px) {

    .career-header-text {
        font-weight: normal;
        font-size: 30px;
        color: var(--header-color);
        font-family: var(--font-two) !important;
    }

    .join-reason-header .join-reason-header-text {
        font-size: 28px;
        display: block;
    }

}