.image-item img {
  width: 100%;
  border-radius: var(--radius);
  height: 40rem;
  object-fit: cover;
  margin-bottom: 2rem;
}

.upload__image-wrapper .fas.fa-upload {
  font-size: 4rem;
  width: 100%;
  margin-bottom: 2rem;
  margin-right: 2rem;
  border-radius: 50%;
  background: transparent;
  height: 8rem;
  width: 8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.upload__image-wrapper {
  /* overflow: auto; */
}
.image-item > img {
  height: 500px;
  object-fit: cover;
}

.upload__image-wrapper .flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin: 0;
}
.upload__image-wrapper .sticky {
  /* position: sticky;
  top: 0;
  background: transparent;
  padding-bottom: 1rem;
  padding: 0rem 2rem;

  text-align: left; */
}

.upload__image-wrapper .flex .fas {
}

.upload__image-wrapper .flex > div {
  flex: 9;
}
