.stepper-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  display: flex;
  align-items: center;
}

.divider {
  width: 100%;
  max-width: 320px;
  height: 1px;
  margin: 0 5px;
}

.stepper-container-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150vh;
}

.step-vertical {
  display: flex;
  align-items: center;
}

.divider-vertical {
  width: 1px;
  height: 100%;
  max-height: 320px;
  margin: 5px 0;
  background-color: var(--border-color);
}