.busy-container {}

.busy-active {
  position: fixed;
  left: 0;
  z-index: 1000;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-size: 8rem;
  background-color: var(--background);
  align-items: center;
  opacity: 0.3;
  backdrop-filter: blur(20px);
}

.busy-active .fa-spin {
  color: var(--theme-color);
}