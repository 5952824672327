.subtotal {
  padding: 3rem;
  border-radius: var(--radius);
  box-shadow: 0 0 4rem var(--shadow-color);
  background-color: var(--foreground);
  color: var(--font-color);
  position: sticky;
  top: 23rem;
  margin-bottom: 2rem;
  margin-top: 0rem;
}

.subtotal__gift > input {
  margin-right: 5px;
}

/* .input-field {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.input-field::placeholder {
  color: #999;
}

.input-field-container {
  position: relative;
} */

/* .input-field { */
/* padding-right: 30px; Adjust the padding to accommodate the icon */
/* } */
/* 
.icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the position of the icon */
/* transform: translateY(-50%);
  color: #888;
} */
/* .icon.fas.fa-check {
  color: var(--green);
}
.icon.fas.fa-times {
  color: var(--red); */
/* } */

/* .input-field.invalid {
  border-color: var(--red);
  border-width: 2px;
}

.input-field.valid {
  border-color: var(--green);
  border-width: 2px;
/* } */
