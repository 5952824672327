.payment-method-card {
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.payment-method-card.selected {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.payment-method-card .selected-icon {
  color: var(--header-color);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
}
