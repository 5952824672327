.header-text-home {
  font-weight: normal;
  font-size: 6rem;
  color: var(--header-color);
  font-family: var(--font-two);
}

.header-text-home span {
  font-weight: bold;
  font-family: var(--font-two);
}

.subheader-text {
  font-size: 20px;
}

.why-choose-header {
  font-weight: normal;
  font-size: 7rem;
  color: var(--header-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  text-align: center;
}

.why-choose-header>.header-text {
  display: flex;
  align-items: center;
  font-size: 6rem;
  font-weight: normal;
  font-family: var(--font-two);
}

.why-choose-header>.header-text>span {
  font-weight: bold;
  margin-left: 15px;
  font-size: 6rem;
  font-family: var(--font-two);
}

.why-choose-header>p {
  font-size: 20px;
  margin-top: 1rem;
}

.why-choose-header>.header-text.themed {
  color: var(--background);
}

.icon-text {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .header-text-home {
    font-weight: normal;
    font-size: 30px;
    color: var(--header-color);
  }

  .header-text-home span {
    font-weight: bold;
  }

  .subheader-text {
    font-size: 20px;
  }

  .why-choose-header {
    font-size: 30px;
  }

  .why-choose-header>.header-text {
    font-size: 28px;
    display: block;
  }

  .why-choose-header>.header-text>span {
    font-size: 28px;
  }

  .why-choose-header>p {
    font-size: 16px;
    margin-top: 20px;
  }
}