.logo-img {
  height: 53px;
  width: 38px;
  margin-right: 20px;
  color: var(--red);
}

.footer-link {
  font-size: 14px;
  line-height: 40px;
}

.copyright-text {
  font-size: 14px;
  line-height: 40px;
  color: var(--background);
  font-family: Quicksand;
}

.copyright-text>span {
  font-family: Quicksand;
  margin-left: 10px;
}

.why-choose-header {
  font-weight: normal;
  font-size: 7rem;
  color: var(--header-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  text-align: center;
}

.why-choose-header>.header-text {
  display: flex;
  align-items: center;
  font-size: 6rem;
  font-weight: normal;
}

.why-choose-header>.header-text>span {
  font-weight: bold;
  margin-left: 15px;
  font-size: 6rem;
}

.why-choose-header>p {
  font-size: 20px;
  margin-top: 1rem;
}

.why-choose-header>.header-text.themed {
  color: var(--background);
}

@media only screen and (max-width: 480px) {
  .logo-img {
    height: 30px;
    width: 25px;
  }
}