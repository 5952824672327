.task-message-input {
    border: none !important;
    background-color: var(--foreground);
    width: 100%;

}

.task-message-input-container {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}

.tasks-messages-container-image {
    display: flex;
    overflow-x: auto;
    margin: 2rem 0;
    scrollbar-width: none;
}

.tasks-messages-image {
    height: auto;
    width: 200px;
    border-radius: 10px;
    object-fit: contain;
    margin-right: 1rem;
}

.attach-icon {
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}