.uploader-icon {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--foreground);
  padding: 2rem;
  padding-top: 4rem;
  border-radius: var(--radius);
  margin-top: 2rem;
}

.uploader-icon .fas {
  margin-bottom: 2rem;
  width: 9rem;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  border-radius: var(--radius);
}

.file-card {
  background-color: var(--foreground);
  padding: 1.4rem;
  border-radius: var(--radius);
  margin-top: 2rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file-card__content__actions {
  position: absolute;
  top: 0rem;
  right: 1rem;
}

.file-card__content__status .link {
  text-decoration: underline;
  /* color: var(--header-color); */
  font-weight: bold;
}

.file-card__content__actions .icon-button-icon {}

.file-card__img__img {
  width: 100%;
  width: 13rem;
  height: 7rem;
  position: relative;
  display: flex;
  border-radius: var(--very-small-radius);
  object-fit: cover;
}

.file-card__pdf__pdf {
  width: 100%;
  width: 13rem;
  height: 7rem;
  position: relative;
  display: flex;
  border-radius: var(--very-small-radius);
  object-fit: cover;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
}

.file-card__content {
  margin-left: 1.5rem;
}

.file-card__content .header {
  width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-card__content__status {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-top: 0.4rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  /* border-radius: var(--small-radius); */
}