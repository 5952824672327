.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
  text-align: center;
}
.Home {
  /* background-image: url('https://www.transparenttextures.com/patterns/cubes.png'); */
}
.banner-container {
  background-color: red;
  text-align: center;
}
.banner-container img {
  max-width: 35vw;
  margin: 0rem auto -0.5rem;
  animation: from-bottom 1s ease forwards;
  transform: translateY(20%);
  opacity: 0;
}
@keyframes from-bottom {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(00%);
    opacity: 1;
  }
}
.home-section {
  padding-top: 0rem;
}

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  object-fit: cover;
}
.MuiAccordion-root::before {
  left: 12px !important;
  width: calc(100% - 24px) !important;
  display: none;
}
.MuiAccordion-root:nth-child(0)::before {
  display: block;
}

@media only screen and (max-width: 766px) {
  .banner-container img {
    width: 100vw;
    max-width: 100vw !important;
  }
  .home-section {
    padding-top: 3rem;
  }
}
