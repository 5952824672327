.scroll-container {
  position: relative;
  width: auto;
  white-space: nowrap;
}
.scroll-content {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  animation: scroll 15s linear infinite;
}
.scroll-right-to-left {
  display: inline-block;
  animation: scroll 100s linear infinite;
  margin-bottom: 15px;
}

.scroll-right-to-left:hover {
  animation-play-state: paused;
}

.paused {
  animation-play-state: paused;
}
.success-store-card .header,
.success-store-card p {
  white-space: normal;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.success-story-card {
  width: 400px;
  min-height: 220px;
  margin-right: 20px;
}

.success-story-card .card-content {
  display: flex;
}

.success-story-card .card-avatar {
  flex: 0.7;
}

.success-story-card .card-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.success-story-card .card-info {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
