.vertical-banner {
  padding: 4rem;
  margin-top: 3rem;
  border-radius: var(--radius);
  background-color: var(--foreground);
}

.vertical-banner-top-img {
  border-radius: var(--small-radius);
  object-fit: cover;
  width: 100%;
  margin-bottom: 3rem;
  user-select: none;
}

.vertical-banner-bottom-img {
  user-select: none;
  object-fit: cover;
  border-radius: var(--small-radius);
  width: 100%;
  margin-top: 4rem;
}