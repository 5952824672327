.terms-policies-text {
  cursor: pointer;
  color: var(--theme-color);
  font-weight: bold;
}

.floating-icon-container {
  position: absolute;
  cursor: pointer;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
}

.floating-icon {
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: white;
  object-fit: contain;
  width: 70px;
  /* height: 40px; */
}

.verification-container {
  position: relative;
  /* display: inline-block; */
}

.verification-container .resend-button {
  border-radius: 1.6rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -3px;
  border: 1px solid var(--border-color);
}

.google_logo {
  height: 100%;
  padding: 1.5rem;
}
