.thumbnail-container {
  display: flex;
  flex-direction: column;
  height: 200px;
  border-radius: var(--small-radius);
  overflow: hidden;
}

.thumbnail-image-container {
  flex: 3;
  position: relative;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 8rem;
  width: 8rem;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play-button-icon {
  color: white;
  font-size: 24px;
}

.details-container {
  flex: 2;
  background-color: var(--background);
  padding: 10px 1rem 1rem 2rem;
}

.details-container .header {
  line-height: 1.4;
}

.details-description {
  /* font-size: 10px; */
}

@media only screen and (max-width: 1024px) {
  .thumbnail-container {
    min-height: 220px;
  }

  .thumbnail-image-container {
    min-height: 150px;
  }
}
