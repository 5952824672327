.message-popup-right>div,
.message-popup>div {
  background-color: var(--foreground);
  border-radius: var(--small-radius);
  background-color: var(--background);
  padding: 1.2rem 1.5rem;
  max-width: 80%;
  width: 80%;
  margin: 1rem 0;
  position: relative;
  min-height: 4rem;
  font-weight: bold;
}

.message-popup {
  display: flex;
  justify-content: flex-start;
}

.message-popup-right {
  display: flex;
  justify-content: flex-end;
}

.message-popup .time-stamp {
  font-size: 1.2rem;
  position: absolute;
  font-weight: normal;
  right: 1rem;
  bottom: 0.5rem;
}