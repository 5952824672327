.icon-button {
  border-radius: 40px;
  padding: 1rem;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  margin-right: 1.5rem;
  background-color: var(--background);
  color: var(--theme-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* .icon-button:hover {} */

.icon-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 4.5rem;
  height: 4.5rem;
  background-color: var(--foreground);
  border-radius: 50%;
  margin-right: 1.2rem;
  position: relative;
}

.icon-button-text {
  white-space: nowrap;
  text-align: left;
  font-size: 2rem;
}

.icon-button.lighter {
  background-color: var(--foreground);
}

.icon-button-icon.lighter {
  background-color: var(--background);
  color: var(--theme-color);
}

.icon-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon-button.larger .icon-button-icon {
  font-size: 4rem;
  width: 8rem;
  height: 8rem;
}

.icon-button.larger .icon-text {
  font-size: 2.5rem;
}

.icon-button.small .icon-button-icon {
  font-size: 1.8rem;
  width: 3rem;
  height: 3rem;
  background-color: white !important;
}

.icon-button.small .icon-button-text {
  font-size: 2rem;
}

.icon-button.small {
  margin-right: 0;
}

.icon-button.info .icon-button-icon {
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  color: #d1b72f;
}

.icon-button.info .icon-button-text {
  font-size: 2rem;
  color: var(--background);
}

.icon-button.info {
  margin-right: 0;
  background-color: #d1b72f;
  height: fit-content;
}

.circular-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--background);
  border-top: 2px solid var(--theme-color);
  border-left: 2px solid var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
  animation: spin 2s linear infinite;
}

.icon-button.active .icon-button-icon {
  background-color: var(--background);
}

.icon-button.selected .icon-button-text {
  color: var(--white);
}

.icon-button.selected .icon-button-icon {
  background-color: var(--white);
  color: var(--green);
}

.icon-button.selected {
  background-color: var(--green);
  padding: 5px 10px 5px 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circular-progress.hidden {
  display: none;
}