.MuiCircularProgress-circle {
  stroke: var(--loader-color) !important;
}

.MuiSkeleton-pulse {
  background-color: var(--background) !important;
}

.MuiSkeleton-root.MuiSkeleton-rectangular {
  background-color: var(--background) !important;
}