.v2-product-page-container {
  margin: 4rem 0;
}

.v2-product-page-inner {
  padding: 5%;
  padding-top: 0%;
  margin-top: 0;
}
.product-image-store-name {
  position: absolute;
  left: 0;
  top: 0;
}
.v2-product-page-container h1 {
  margin: 2rem 0;
  text-transform: capitalize;
  font-weight: bold;
}
.product-name {
  font-size: 4rem;
}

.product-desc {
  margin: 2rem 0;
  font-size: 2.5rem;
}

.product-price {
  margin: 1rem 0;
  font-size: 5rem;
  font-weight: 200;
  color: var(--theme-color);
  display: inline-block;
  position: relative;
}

.product-price .discount-sign {
  position: absolute;
  right: 0%;
  font-size: 2.5rem;
  top: 0;
  font-weight: bold;
  color: green;
}
.product-page-img-container {
  position: relative;
}
.product-page-img-container.big-images .image-gallery {
  max-width: 100%;
  margin-top: 5rem !important;
}
.product-brand {
  color: var(--font-color);
}

.product-page-img-container .cc-img-parent {
  z-index: 1;
  position: relative;
  width: 85%;
  margin: 0 auto;
  height: 60rem;
  margin-bottom: 10%;
}

.product-page-img-container .cc-img-parent .compact-card-image-shadow {
  filter: blur(20px);
}

.product-page-head {
  font-size: 2rem;
}
.product-page-extra-action {
  margin-top: 2rem;
}

.v2-product-page-container .actions {
  height: 8rem;
  margin: 0;
  min-width: none;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  border-radius: var(--radius);
}
.v2-product-page-container .actions button {
  width: 10rem !important;
  height: 8rem;
  margin: 0;
  cursor: pointer;
  min-width: none;
  position: relative;
  border: 1px solid var(--theme-color) !important;
  right: 0%;
  border: none;
  color: var(--theme-color);
  background-color: var(--background) !important;
  border-radius: var(--radius);
}
.v2-product-page-container .actions button:first-child {
  width: 25% !important;
  padding-right: 0rem;
  border: 1px solid var(--border-color) !important;
  margin-right: 2%;
}
.v2-product-page-container .actions button:first-child .fas {
  color: var(--theme-color);
  margin-right: 20%;
}
.v2-product-page-container .actions button:last-child {
  background-color: var(--theme-color) !important;
  width: 80% !important;
  color: var(--foreground);
  line-height: 2;
  font-weight: bold;
  font-size: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0%;
}
.v2-product-page-container .actions button:last-child .fas {
  margin-right: 1.2rem;
}
.v2-product-page-container .lnr-cart {
  color: var(--foreground);
  margin-right: 2rem;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 10rem;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  border-radius: var(--radius);
  max-height: unset !important;
  width: 100%;
  object-fit: contain;
  min-height: 40rem;
  /* height: 100%; */
  object-fit: cover;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  border-radius: var(--radius);
  height: 100px;
  width: 100%;
  object-fit: cover;
}
.image-gallery-thumbnail {
  border-radius: var(--radius);
}
.product-type {
  padding: 1.8rem 2rem;
  border-radius: var(--radius);
  background: var(--light-blue);
  color: var(--blue);
  line-height: 0;
  display: block;
  display: inline-block;
}
.product-type.Service {
  background: var(--light-purple);
  color: var(--purple);
}
@media only screen and (max-width: 766px) {
  .v2-product-page-inner {
    padding: 0%;
    border-radius: var(--radius);
    margin-top: 0;
  }
  .v2-product-page-container {
    margin-top: 0;
    margin-bottom: 10rem;
  }
  .product-page-img-container .cc-img-parent {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 10%;
    height: auto;
    margin-bottom: 10%;
  }
  .cc-img-parent .compact-card-image {
    width: 100%;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    /* background-size: cover; */
  }
  .cc-product .cc-img-parent .compact-card-image {
    height: 40rem;
  }
  .product-page-img-container .cc-img-parent .compact-card-image-shadow {
    background-size: cover;
    width: 100%;
    margin: 0 auto;
  }
  .v2-product-page-container .actions button:last-child {
    background-color: var(--theme-color) !important;
    border-radius: var(--radius);
    width: 100% !important;
    color: var(--foreground);
    line-height: 2;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
  }
}
