.Mui-completed.css-ln58tu-MuiSvgIcon-root-MuiStepIcon-root {
  color: var(--theme-color) !important;
}

.MuiStepConnector-root,
.MuiStepContent-root {
  /* margin-left: 17px !important; */
}

.MuiStepper-root .MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
}

.MuiStepIcon-text.css-ak0ww7-MuiStepIcon-text {
  fill: var(--foreground) !important;
}