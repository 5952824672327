.store-home-card {
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.store-home-card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.store-home-tags {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 10px;
}

.store-home-tags span {
    background-color: rgba(201, 201, 201, 0.7);
    color: #000000;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 1rem;
}

.store-home-card-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.089), rgb(255, 255, 255));
    padding: 10px;
}

.store-home-card-content h2 {
    margin: 0;
    font-size: 5rem;
    color: var(--background);
}

.store-home-card-content p {
    margin: 5px 0;
    font-size: 1em;
}

.cta-button {
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #1ba3c4;
}


.product-card {
    width: 250px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    font-family: Arial, sans-serif;
    color: #333;
    padding: 1.5rem;
}

.product-image {
    position: relative;
}

.product-image img {
    width: 100%;
    display: block;
    border-radius: 1.5rem;
}

.wishlist-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #f00;
}

.product-info {
    margin-top: 1.5rem;

}

.product-name {
    font-size: 18px;
    font-weight: bold;
}

.product-description {
    color: #666;
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.product-price {
    color: #e60000;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.product-color {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.color-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 4px;
}

.color-indicator.red {
    background-color: red;
}

.color-indicator.blue {
    background-color: blue;
}

.color-indicator.yellow {
    background-color: yellow;
}

.color-label {
    font-size: 14px;
    color: #555;
}

.buy-button {
    width: 100%;
    background-color: #e60000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.buy-button:hover {
    background-color: #cc0000;
}

.product-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5rem;
}

.product-actions span {
    font-size: 3rem;
    color: #666;
}