.price-card {
  border-radius: var(--radius);
  padding: 2rem 3rem;
  color: var(--theme-color);
  position: relative;
  background-color: var(--foreground);
  overflow: hidden;
  border: 1px solid transparent;
  margin-bottom: 4rem;
}
.price-card label {
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  margin-bottom: 3rem;
  display: inline-block;
}

.price-card .background {
  position: absolute;
  right: -10%;
  bottom: -20%;
  width: 30rem;
  height: 30rem;
  z-index: 1;
  opacity: 0.2;
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotate 90s linear 0.2s forwards;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.price-card.gold {
  background-color: var(--theme-color);
  position: relative;
  color: var(--foreground);
}

.price-card.black-gold {
  border: 1px solid;
}
.price-card.silver {
}

.price-card .price {
  font-size: 9rem;
  font-weight: bold;
  line-height: 1;
}
.price-card .amount {
  position: relative;
  z-index: 2;
}
.price-card .amount span {
  font-size: 2rem;
  font-weight: normal;
}
