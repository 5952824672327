.expander-new-container {
    width: 100%;
}

.expander-new-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: var(--background);
    border-radius: var(--radius);
    cursor: pointer;
}

.expander-new-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    margin-top: 10px;
    opacity: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.expander-new-content.expanded {
    max-height: 1000px;
    opacity: 1;
    padding: 10px 15px;
    margin-bottom: 2rem;
}

.expander-new-children {
    padding: 0px 20px;
}

.expander-new-content-inner {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}