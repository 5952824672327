.footer {
  background: var(--font-color);
  color: var(--font-color);
  padding: 2rem;
  border-radius: var(--radius);
  margin: 0 0;
  margin-top: 2rem;
  /* box-shadow: -0rem -0.2rem 2rem var(--shadow-color); */
}

.footer .links a {
  width: 50%;
  display: inline-block;
  margin: 12px 0;
  color: var(--white);
}

.footer .links a:hover {
  text-decoration: underline;
  cursor: pointer !important;
}

.footer .container {
  padding: 0;
}

.footer .header {
  text-align: center;
}

.footer>div>div:first-child {
  /* height: 20rem; */
  padding: 5rem 0;
  /* border-top: 1px solid var(--shadow-color); */
}

.footer a {}

.footer-links {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--background);
}

.footer-links>a {
  padding: 1rem 0;
  color: var(--background);
}

.footer-links>a:hover {
  color: var(--background);
  text-decoration: underline;
}

.footer-col {
  padding-right: 0;
}

.social-links {
  padding: 2rem 0;
}

.social-links>a {
  width: 5rem;
  height: 5rem;
  font-size: 2rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: var(--background);
  border: 1px solid var(--background);
}

.social-links>a:last-child {
  margin-right: 0;
}

.social-links>a:hover {
  color: var(--font-color);
}

.footer-long {
  display: flex;
  padding: 1rem;
  background: var(--foreground);
  padding: 2rem;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  filter: grayscale(1);
}

.footer-long div {
  display: inline-flex;
  margin-right: 20px;
  width: auto;
}

.footer-long div img {
  height: 20px;
  margin-right: 10px;
}

.footer-long div .header {
  font-size: 12px;
  padding-left: 5px;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 0 0rem;
    /* border-radius: 3.5rem; */
    margin-top: 1rem;
    padding-bottom: 1rem !important;
  }

  .footer>div>div:first-child {
    padding: 2rem 0;
  }

  .footer-col {
    padding: 2rem;
  }

  .footer .links a {
    width: 50%;
    display: inline-block;
    text-align: center;
    margin: 10px 0;
  }
}