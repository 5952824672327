.color-card-container {
    display: flex;
    height: 50px;
    background-color: var(--background);
    border-radius: 10px;
    max-width: fit-content;
}

.color-block {
    width: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-code {
    border-radius: 10px;
    background-color: var(--background);
    display: flex;
    align-items: center;
    padding: 0px 10px;
    color: var(--header-color);
    font-size: 18px;
}

.corners-card-container {
    background-color: var(--background);
    width: 100%;
    max-width: 200px;
    height: 120px;
    position: relative;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
}

.corners-block {
    background-color: var(--font-color);
    width: 100%;
    max-width: 100px;
    height: 70px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 10px;
}

.rounded {
    border-top-left-radius: 20px;
}

.square {
    border-bottom-right-radius: 10px;
}

.check-icon {
    color: var(--header-color);
    font-size: 20px;
}

.template-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-preview {
    /* height: 450px;
    width: 380px; */
    background-color: var(--foreground);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.template-preview-content {
    padding: 15px;
    height: 100%;
}

.template-image-container {
    width: 100%;
    object-fit: contain;
    position: relative;
}

.out-of-stock-label {
    padding: 5px 15px;
    background-color: var(--foreground);
    font-size: 16px;
    font-weight: 500;
    color: var(--error);
    position: absolute;
    top: 5px;
    right: 5px;
}

.template-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 260px);
}

.template-actions-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-to-cart-button {
    padding: 10px 20px;
    font-size: 16px;
    color: var(--foreground);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rounded-corners {
    border-radius: 15px;
}

.rounded-corners-button {
    border-radius: 30px;
}

.rounded-corners-img {
    border-radius: 10px;
}

.square-corners {
    border-radius: 0;
}

.category-tag {
    margin-top: 5px;
    padding: 3px 15px;
    font-size: 12px;
    font-weight: 500;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.1);
}

.discount-percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 2px 6px;
    font-size: 8px;
    font-weight: bold;
}

@media only screen and (max-width: 1024px) {

    .add-to-cart-button {
        margin-top: 10px;
    }

    /* 
    .category-tag {
        margin-top: 10px;
    } */

}