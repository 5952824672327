.icon-button-container {
  display: flex;
  /* align-items: center; */
  font-size: 10px;
}

.icon-button-circle > p {
  font-size: 3rem;
  font-weight: 700;
  color: var(--theme-color);
}

.icon-button-circle {
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-color: var(--background);
  color: var(--theme-color);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button-circle.small {
  height: 25px;
  width: 25px;
}

.icon-button-circle.large {
  height: 40px;
  width: 40px;
}

.icon-button-circle.accent {
  color: var(--theme-color);
  background-color: var(--foreground) !important;
  font-size: 1.5em;
  height: 40px;
  width: 40px;
}

.icon-button-circle .fa-icon {
  font-size: 1.5em;
}

.icon-button-circle.with-shadow {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
