.store-logo-circle {
    height: 30px;
    width: 30px;
    font-size: 16px;
    color: var(--foreground);
    background-color: var(--error);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-cover {
    object-fit: contain;
    width: 100%;
    height: auto;
    border-radius: 8px;
}