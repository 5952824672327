.price-card-container {
    width: 350px;
    height: 700px;
}

@media only screen and (max-width: 1024px) {

    .price-card-container {
        width: 100%;
        height: 700px;
    }
}