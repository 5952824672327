.best-seller-card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.best-sellers-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.best-seller-card-header {
  font-weight: bold;
  font-size: 13px;
}

.best-seller-card-text {
  font-size: 10px;
}

.best-seller-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-seller-card-sale {
  background-color: var(--background);
  border-radius: 10px;
  padding: 5px 10px;
}

.best-seller-card-sale span {
  font-weight: bold;
}

.best-seller-category-img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

@media only screen and (max-width: 766px) {

  .best-seller-card-img {
    height: auto !important;
  }
}