.analytics-tile {
  background-color: var(--background);
  border-radius: var(--radius);
  padding: 10px 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--header-color);
  margin-bottom: 10px;
}
.analytics-tile.light {
  background-color: var(--foreground);
  color: var(--header-color);
}

.analytics-icon-container {
  display: flex;
}

.analytics-icon {
  font-size: 25px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.analytics-tile:hover .analytics-icon {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.analutics-text-container {
  display: flex;
  flex-direction: column;
}
.analytics-text-header {
  font-weight: bold;
}
