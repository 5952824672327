.search-list-container {
  transform: translateY(100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  padding: 2rem 2rem;
  /* color: red; */
  width: 100%;
  background: var(--white);
  border-radius: var(--radius);
}
.search-list-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 110vh;
  backdrop-filter: blur(10px);
  z-index: 1;
  background: #252525a0;
  left: -50vw;
  width: 200vw;
  top: -4rem;
}
.small-busy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.search-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-list-header > span:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: var(--radius);
}
.search-list-header > span:last-child:hover {
  background-color: var(--foreground);
  cursor: pointer;
}
.search-list-container a {
  width: 100%;
  padding: 2rem;
  height: auto;
  display: block;
  margin: 0rem;
  border-radius: var(--radius);
}
.search-list-container a:hover {
  background-color: var(--foreground);
}
.search-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.search-list-item img {
  width: 10rem;
  margin-right: 2rem;
  height: 10rem;
  object-fit: cover;
  border-radius: var(--radius);
}

.search-list-item h4 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--theme-color);
}
.search-list-item h5 {
  font-weight: normal;
}

@media only screen and (max-width: 766px) {
  .search-list-container {
    top: 0;
    bottom: unset;
    transform: translateY(-100%);
  }
  .search-list-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    top: unset;
    width: 100vw;
    height: 110vh;
    backdrop-filter: blur(10px);
    z-index: 1;
    background: #252525a0;
    left: -50vw;
    width: 200vw;
    top: -100vh;
  }
}
