.image-preview-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 102;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image-preview-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
}

.image-preview-container .image {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  max-height: 80%;
  z-index: 103;
  object-fit: contain;
  animation: zoomAndFadeIn 0.3s ease-out forwards;
  transform: translate(-50%, -50%) rotate(-10deg) scale(1);
  border-radius: 16px;
}

.image-preview-container .image-preview-close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: var(--background-color);
  font-size: 20px;
  cursor: pointer;
  z-index: 104;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

@keyframes zoomAndFadeIn {
  0% {
    transform: translate(-50%, -50%) rotate(-10deg) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    opacity: 1;
  }
}