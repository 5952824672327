.view-by-dropdown {
    margin: 10px;
    font-size: 15px;
    border-color: var(--border-color);
    height: 5rem;
}

.view-by-dropdown label {
    margin-right: 10px;
}

.view-by-dropdown select {
    background-color: var(--background);
    border-radius: 30px;
    padding: 5px;
    font-size: 2rem;
    border: none;
    font-weight: 500;
    font-family: 'Quicksand';
    height: 100%;
    color: var(--theme-color);
}