.standalone-uploader {
  padding: 1rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  position: relative;
  padding-bottom: 0;
  background-color: var(--foreground);
}

.standalone-error,
.standalone-error label {
  border-color: var(--error-font-color);
  color: var(--error-font-color);
}

.standalone-error {
  box-shadow: 0 0 0 0.5rem var(--error-background-color);
}

.standalone-uploader .image-item__btn-wrapper {
  margin-top: 2rem;
}

.standalone-uploader .image-item img {
  width: auto;
  height: 200px;
}

.upload-icon {
  padding: 3rem;
  margin: 2rem;
  font-size: 4rem;
  background-color: var(--background);
  border-radius: 2rem;
}

.standalone-uploader>label {
  position: absolute;
  top: -16px;
  width: auto !important;
  left: -15px;
  background-color: var(--background);
  padding: 2rem 2rem;
  border-radius: var(--radius);
  transform: translateY(-50%);
  line-height: 0;
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .standalone-uploader {
    border: 2px solid var(--border-color);
  }
}