.new-tag {
  padding: 0.75rem 1.5rem;
  background-color: var(--background);
  border-radius: var(--radius);
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  font-weight: bold;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: default;
  color: var(--font-color);
}
.new-tag-abs {
  position: absolute;
  z-index: 1;
  margin: 0;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  .new-tag {
    padding: 0.6rem 1rem;
    background-color: var(--background);
    border-radius: var(--radius);
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
    font-weight: bold;
    margin-right: 1rem;
    cursor: default;
  }
  .new-tag-abs {
    margin: 0;
  }
}
