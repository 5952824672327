.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  margin-bottom: 4rem;
  font-size: 2rem;
  padding: 20px;
  /* max-height: 400px; */
  min-width: 100px;
  background-color: var(--background);
  color: var(--font-color);
  z-index: 1;
  text-align: left;
  border: 1px solid transparent;
  border-radius: var(--radius);
  box-shadow: -2px 17px 4rem rgb(0 0 0 / 10%);
  position: relative;
}
.product .like-btn {
  position: absolute;
  right: -3rem;
  top: -1rem;
  z-index: 1;
}
.product h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.product .btns {
  width: 100%;
  /* position: absolute;
    opacity: 0;
    display: flex;
    align-items: center;
    align-items: center;
    transition: all 0.3s ease;
    bottom: 0;
    left: 0;
    width: 100%;
    flex-direction: column; */
}

.product .btns button {
  /* margin-bottom: 0 !important; */
  width: 100%;
  margin-right: 0 !important;
  border-radius: var(--radius);
  border-radius: var(--radius);
  margin-bottom: 1rem;
}
.product .btns button:first-child {
  margin-bottom: 2rem;
}
.product .btns button:last-child {
  margin-bottom: 0 !important;
}
.product .btns .btn-circle {
  width: auto !important;
  border-radius: var(--radius);
}
.product:hover .btns {
  opacity: 1;
}
.product__rating {
  display: flex;
}
.product:hover {
  /* border: 1px solid var(--border-color); */
}

.product > img {
  border-radius: 2.5rem;
  height: 200px;
  width: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  margin-bottom: 15px;
}

.product__price {
  margin-top: 5px;
  font-size: 4rem !important;
  color: var(--theme-color);
  font-weight: light;
}

.product__info {
  margin-bottom: 4rem;
  width: 98%;
  /* margin-left: 5%; */
  margin-bottom: 7rem;
  position: relative;
}
.product__info h3 {
  font-size: 3rem;
  margin: 1rem 0;
}

.MuiStepLabel-root * {
  font-size: 2rem !important;
}

.MuiStepper-alternativeLabel {
}

.from-profile .checkoutProduct__image {
  height: auto;
  height: 50px;
}
.fullscreen.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 159px) !important;
  object-fit: contain !important;
}

.image-gallery-content.fullscreen {
  background-color: var(--background);
}
.fullscreen .image-gallery-icon {
  color: var(--font-color);
}

@media only screen and (max-width: 766px) {
  .product .btns {
    opacity: 1 !important;
  }
  .product {
    border: 1px solid var(--border-color) !important;
  }
}
