.info-panel-container {
    display: flex;
    color: white;
    margin: 0.5rem 0px;
    transition: background-color 0.3s;
}

.info-panel-bar {
    min-width: 0.5rem;
    height: auto !important;
    margin-right: 16px;
}

.info-panel-content-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}


.info-panel-title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--header-color);
}

.info-panel-content {
    font-size: 1.5rem;
    line-height: 1.5;
    overflow-wrap: break-word;
    color: var(--font-color);
    margin-top: 0.5rem;
}

.info-panel-status-icon {
    background-color: yellow;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-panel-status-icon i {
    font-size: 1.2em;
    font-weight: 500;
    color: inherit;
}