.expander {
  min-height: 38px !important;
}

.expander-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expander-header h1 {
  font-size: 2.3rem;
  margin: 0.5rem 0;
}

.expander-header .exp-btn {
  width: 5rem !important;
  min-width: unset;
  border-radius: 50%;
  cursor: pointer;
  padding: 0 !important;
  border: transparent !important;
  background-color: var(--foreground);
  background-image: none;
  color: var(--font-color);
  font-weight: bold;
  height: 5rem;
  margin-bottom: 0rem;
}

.expander-header .btn-small.btn-circle {
  height: 5rem !important;
  width: 5rem !important;
  margin-bottom: 1rem;
  min-width: auto !important;
}

.expander-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, 0.5s ease-out;
}

.expander-content.open {
  max-height: 100%;
  overflow: visible;
  /* padding: 5px; */
}

.step-counter {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--header-color);
  color: var(--background);
  text-align: center;
  font-size: 12px;
  padding: 2px;
  margin-right: 10px;
}

.step-status {
  display: inline-block;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: var(--green);
  color: var(--background);
  text-align: center;
  font-size: 12px;
  padding: 4px;
  margin-right: 10px;
}

.step-status i {
  color: white;
}

.step-status.exclamation {
  background-color: var(--header-color);
  color: var(--background);
}

.pending-icon-expander {
  background-color: #E69A28;
  color: var(--foreground);
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  font-size: 10px;
  margin-right: 10px;
}

.label-css {
  font-size: 2.3rem;
  font-weight: regular;
  color: var(--theme-color);
  margin-left: 1rem;
}

.pending-icon i {
  color: orange;
}

.submitted-icon i {
  color: blue;
}

.approved-icon i {
  color: green;
}

.rejected-icon i {
  color: red;
}

.error-icon i {
  color: red;
}

.validating-icon i {
  color: purple;
}