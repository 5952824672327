.ad-status {
    position: absolute;
    font-weight: regular;
    color: white;
    padding: 10px 15px 10px 10px;
    border-bottom-left-radius: 15px;
    right: -35px;
    top: -25px;
}

.ad-banner-middle-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}