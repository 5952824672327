.infinite-carousel {
  position: relative;
}
.infinite-carousel .left-shadow,
.infinite-carousel .right-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, var(--background), transparent);
  z-index: 1;
}
.infinite-carousel .right-shadow {
  left: auto;
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}
.scroll-right-to-left,
.scroll-left-to-right {
  display: inline-block;
  animation: scroll 100s linear infinite;
  margin-bottom: 15px;
}

.scroll-right-to-left:hover,
.scroll-left-to-right:hover {
  animation-play-state: paused;
}

.scroll-left-to-right {
  animation-direction: reverse;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
