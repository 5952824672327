.shippment-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--foreground);
  padding: 2rem;
  margin-right: 2rem;
  border-radius: var(--radius);
  width: 100%;
  margin-bottom: 1rem;
}

.li-check-circle {
  font-size: 14px;
  font-weight: bold;
  font-family: Quicksand;
  margin-bottom: 10px;
}

.li-check-circle>i {
  color: var(--header-color);
}

.span-check-circle {
  margin: 0px 10px;
  color: var(--header-color);
  white-space: nowrap;
}