.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 10rem;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 1s ease-in-out;
  color: var(--header-color);
}

.carousel-content.exiting {
  opacity: 0;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: var(--font-color);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.indicator.active {
  background-color: var(--header-color);
}