.primary-menu {
  border-radius: var(--radius);
  background: var(--foreground);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  margin-top: 2rem;
  position: fixed;
  top: 9.5rem;
  left: 1rem;
  z-index: 100;
  width: 9rem;
  overflow-y: auto;
  transition: all 0.3s ease;
  scrollbar-width: none;
  overflow-x: hidden;
  max-height: calc(100vh - 13.5rem);
}

.primary-menu-link-wrapper {
  display: block;
  width: calc(100% - 0.2rem);
}

.primary-menu-active {
  width: 35rem;
  box-shadow: 1rem 0 2rem rgba(0, 0, 0, 0.1);
}

.primary-menu-active .primary-menu-link-label {
  padding-right: 4rem;
  transition: none;
  font-family: var(--font-two);
}

.primary-menu-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33rem;
}

.primary-menu-link {
  padding: 1.2rem 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: var(--small-radius);
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
}

.primary-menu-link-label {
  opacity: 0;
  white-space: nowrap;
}

.primary-menu-caret {
  opacity: 0 !important;
}

.primary-link-visible .primary-menu-link-label {
  opacity: 1;
}

.primary-link-visible .primary-menu-caret.primary-menu-caret-active {
  opacity: 1 !important;
}

.primary-menu-caret {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  right: 2rem;
  font-size: 2rem;
  opacity: 0;
  transition: all 0.5s ease;
}

.primary-menu-caret-active {
  opacity: 1;
  transform: translateY(-50%) translateX(0%);
}

.primary-menu-link .primary-menu-link-icon {
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 4rem;
  margin-right: 2rem;
  margin-left: 1rem;
  font-size: 2.1rem;
  color: var(--header-color);
  transition: all 0.5s ease;
}

.primary-menu-active .primary-menu-link .primary-menu-link-icon {
  background-color: var(--background);
}

.primary-menu-link:hover,
.primary-menu-link-active {
  background-color: var(--background);
  /* background-color: red; */
}

.primary-menu-link:hover .primary-menu-link-icon {
  background-color: var(--foreground);
}

.primary-menu-link-icon {
  background-color: transparent;
}

.primary-menu-active .primary-menu-link-icon {
  background-color: var(--foreground);
}

.primary-menu-link-label {
  font-weight: bold;
  font-size: 2.1rem;
  color: var(--header-color);
}

.primary-menu-section {
  padding: 1rem;
  opacity: 0;
  transition: all 0.2s ease;
  padding-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
  /* background-color: red; */
  width: 100%;
}

.primary-menu-section .header {
  color: var(--font-accent-color);
  text-align: center;
}

.primary-menu-active .primary-menu-section {
  opacity: 1;
}



.primary-menu-store-select {
  padding: 2rem 0;
  margin: 0;
  width: 100%;
  padding-bottom: 2rem;
}

.primary-menu-store-select * {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.primary-menu-store-select select {
  margin: 0;
}

.primary-menu .primary-menu-store-select .header {
  opacity: 0;
  transition: all 0.2s ease;
  /* margin-left: 1.5rem; */
  margin-bottom: 0;
}

.primary-menu-active .primary-menu-store-select .header {
  opacity: 1;
}

.primary-menu-store-select .input-field.select {
  margin: 0;
  height: 6rem;
  margin-top: 2rem;
  padding-right: 1rem;
  border-radius: var(--small-radius);
}

.primary-menu-store-select .input-field.select select {
  font-size: 2rem;
  /* margin-top: 1rem !important; */

}

.primary-menu .primary-menu-store-select {
  width: 7rem;
}

.primary-menu-active .primary-menu-store-select {
  width: 100%;
  margin-left: 1.5rem;
  width: calc(100% - 3rem);
}

.primary-menu-active .primary-menu-store-select .input-field.select {
  padding-right: 1rem;
}

.primary-menu-link-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media only screen and (max-width: 766px) {
  .primary-menu-links {
    width: 100%;
  }

  .primary-menu-active {
    width: 100%;
    max-width: -webkit-fill-available;
    box-shadow: none !important;
    /* margin-top: -12px; */
    /* height: 85vh;
    overflow-y: auto; */
  }

  .primary-menu {
    position: static;
    margin-top: 0;
  }
}