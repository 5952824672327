.notif-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  padding: 1rem 0;
  padding-bottom: 2rem;
}
.notif-item span.content {
  padding-right: 1rem;
}
.notif-item span.action {
  text-align: right;
  padding-top: 2rem;
}
.notif-item .timestamp {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
