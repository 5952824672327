.ticket-card {
  transition: filter 0.3s ease-out;
  cursor: pointer;
}

.ticket-card .avatar-chip {
  background-color: var(--foreground);
  padding: 5px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-chip.large {
  background-color: var(--foreground);
  padding: 12px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
}

.avatar-chip.accent {
  background-color: var(--background);
  padding: 12px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.avatar-chip.accentAlt {
  background-color: var(--header-color);
  color: var(--background);
  padding: 12px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.status-chip {
  background-color: var(--foreground);
  border-radius: 30px;
  padding: 4px 10px !important;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
}

.status-chip.open {
  color: var(--red);
}

.status-chip.closed {
  color: var(--green);
}

.status-chip.inProgress {
  color: #d4ac0d
}

.status-chip.accent {
  background-color: var(--background);
  height: 2.5rem;
  width: fit-content;
  font-size: 1.5rem;
}

.ticket-card .new-message {
  font-weight: bold;
  color: var(--header-color);
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.new-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-card .new-message .icon-circle {
  color: var(--green);
  font-size: 8px;
  margin-right: 5px;
  padding: 0;
}

.attachment-container {
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.attachment-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 400px;
}

.attachment-details {
  background-color: var(--background);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: var(--theme-color);
}

.attachment-name {
  color: var(--theme-color);
}

.attachment-view-link {
  text-decoration: underline;
  color: var(--theme-color);
}

.attachments-wrapper {
  max-height: 35vh;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: none !important;
  ;
}