.tabs-content-containers {
  padding: 2rem 0rem;
}

.tabs {
  /* box-shadow: 0 0 1rem var(--shadow-color); */
  margin: 4rem 0;
  border-radius: var(--radius);
  padding: 1rem 0rem;
}

.tabs * {
  transition: all 0.2s ease-out;
}

.tabs-button-container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tab-button {
  width: auto;
  cursor: pointer;
  padding: 0rem 0rem;
  min-height: 5rem;
  font-size: 2rem;
  background: none;
  color: var(--font-color);
  font-weight: bold;
  position: relative;
  z-index: 0;
  background-color: var(--foreground);
  padding: 0px 15px;
  border-radius: 30px;
  margin-right: 1.5rem;
  border: none;
  border: 1px solid var(--border-color);
  font-family: var(--font-two);
}

.tabs-v2>.tabs-button-container .tab-button {
  border: 3px solid transparent;
  padding: 1rem 0rem;
  background-color: var(--foreground);
  border-radius: 0px !important;
  min-height: 4.5rem;
  margin-right: 2rem;
}

.tabs-v3>.tabs-button-container .tab-button {
  border: 3px solid transparent;
  padding: 0rem 0;
  border-radius: 0px !important;
}

.tabs-v2>.tabs-button-container .tab-button.active {
  border-bottom: 2px solid var(--theme-color);
  color: var(--theme-color);
  border-radius: 0px;
}


.tab-button:hover {
  /* border-bottom: 3px solid var(--font-color); */
}

.tab-button.active {
  border-bottom: none;
  cursor: default;
  border-right: none;
  color: var(--foreground);
  border-top: none;
  /* border-bottom: 3px solid var(--theme-color); */
  background-color: var(--theme-color);
  padding: 0px 15px;
  border-radius: 30px;
  font-size: 2rem;
}

.tab-button.active:last-child {
  /* border-right: 1px solid var(--border-color); */
}

.tab-button span {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.tabs-content {
  opacity: 0;
  height: 0;
}

.tabs-content.active {
  opacity: 1;
  height: auto;
  animation: appear 0.5s ease-out forwards;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .tabs-button-container {
    justify-content: center;
  }

  .tab-button {
    min-height: 6rem;
  }
}