.sales-analytics-card {
    display: flex;
    gap: 10px;
    background-color: var(--foreground);
    padding: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

}

.container-one {
    flex: 1;
}

.container-two {
    flex: 4;
}

.search-bar-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
    max-width: 300px;
    background-color: var(--background);
    width: 100%;
    height: 6rem
}

.search-bar-input-container {
    border: none;
    width: 100%;
    background-color: var(--background);
}