*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@import url("https://fonts.googleapis.com/css?family=Quicksand:500,700");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");

:root {
  font-size: 7px;
}

html {
  /* overflow-x: hidden; */
  color: var(--font-color);
}

.default {
  --theme-color: #222;
  --theme-color-hover: #222;
  --theme-color-3: #7e8081;
  --easing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --animation-time: 0.5s;
  --font-one: "Quicksand", sans-serif;
  --font-two: "Montserrat", sans-serif;
  --radius: 2rem;
  --small-radius: 1.5rem;
  --very-small-radius: 1rem;
  --button-height: 6.5rem;
  --transition: all 0.5s ease;
  --PhoneInputCountryFlag-height: 3rem;
  --PhoneInputCountrySelectArrow-width: 1rem;
}

.theme-color {
  color: var(--theme-color);
}

.__react_component_tooltip {
  border-radius: var(--radius);
  z-index: 3000 !important;
}

p {
  line-height: 1.5;
  font-size: 2rem;
  color: var(--font-color);
}

.no-select {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.theme-dark {
  --theme-color: #fff;
  --header-color: #f5f5f5;
  --font-color: #aaa;
  --font-accent-color: #888;
  --font-secondary-color: #666666;
  --foreground: #171a1d;
  --background: #0a0d0f;
  --overlay-color: #11111155;
  --border-color: #2a2c2d;
  --button-disabled-color: #111;
  --placeholder-color: #444;
  --button-border-color: #555;
  --btn-disabled: #444;
  --toggle-background: #555555;
  --toggle-background-nob: #bbbbbb;
  --button-font-color: #999;
  --shadow-color: #00000077;
  --error-font-color: #ff0000;
  --error: #af3a3a;
  --error-background-color: #ff000022;
  --black: #222;
  --focus-border-color: #aaa;
  --white: #f8f8f8;
  --error-font-color: #a8000d;
  --info-font-color: #005bd3;
  --success-font-color: #05a081;
  --success: #2b8d52;
  --foreground-green: #172719;
  --foreground-blue: #204169;
  --foreground-purple: #c8c9ff;
  --foreground-orange: #282010;
  --foreground-red: #330000;
  --foreground-red-dark: #ff000012;
  --green: rgb(0, 144, 24);
  --purple: rgb(155, 47, 255);
  --blue: rgb(134 191 255);
  --red: rgb(182, 9, 9);
  --orange: rgb(144, 70, 0);
  --light-green: rgba(0, 175, 29, 0.123);
  --light-purple: rgba(100, 0, 194, 0.164);
  --light-blue: rgba(0, 104, 224, 0.219);
  --light-red: rgba(182, 9, 9, 0.246);
  --light-orange: rgba(144, 70, 0, 0.226);
  --sub-title-color: #dedede;
  --filter: grayscale(1) brightness(1) invert(1);
  --loader-color: #ffffff;
}

.theme-light {
  --theme-color: #222;
  --loader-color: #333333;
  --btn-disabled: #bebebe;
  /* --filter: grayscale(1) brightness(0.5); */
  --overlay-color: #00000060;

  --font-color: #939393;
  --font-accent-color: #aaaaaa;
  --font-secondary-color: #cccccc;
  --header-color: #333333;
  --background: #f5f5f5;
  --border-color: #eee;
  --button-disabled-color: #888;
  --placeholder-color: #aaa;
  --toggle-background: #e5e5e5;
  --toggle-background-nob: #bbbbbb;
  --button-font-color: #888;
  --shadow-color: #00000011;
  --error-font-color: #a8000d;
  --error: #af3a3a;
  --info-font-color: #005bd3;
  --success-font-color: #05a081;
  --success: #2b8d52;
  --error-background-color: #ff000022;
  --black: #222;
  --white: #f8f8f8;
  --foreground: #fff;
  --foreground-green: #cafed2;
  --foreground-blue: #dfedff;
  --foreground-purple: #c8c9ff;
  --foreground-orange: #fbdfaa;
  --foreground-red: #ffeaea;
  --foreground-red-dark: #ff000012;
  --green: rgb(0, 144, 24);
  --purple: rgb(100, 0, 194);
  --blue: rgb(0, 105, 224);
  --red: rgb(182, 9, 9);
  --orange: rgb(144, 70, 0);
  --light-green: rgba(0, 175, 29, 0.123);
  --light-purple: #6400c214;
  --light-blue: #0068e01a;
  --light-red: rgba(182, 9, 9, 0.246);
  --light-orange: rgba(144, 70, 0, 0.226);
  --sub-title-color: #888;
}


body {
  color: var(--font-color);
  font-size: 1.6rem;
  font-family: var(--font-one);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10rem;
}

.blur-content {
  opacity: 0.1;
  transition: all 0.4s ease-in-out;
  pointer-events: none;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chip {
  margin-left: 0.8rem;
  background: var(--background);
  border-radius: var(--radius);
  color: var(--header-color);
  padding: 1rem 2rem;
  line-height: 0;
  font-size: 1.5rem;
  line-height: 1.2;
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  text-transform: capitalize;
  align-items: center;
}

textarea {
  font-size: inherit;
}

.MuiPaper-root {
  background-color: var(--foreground) !important;
  border-radius: var(--radius) !important;
}

.MuiPaper-root ul {}

.MuiPaper-root ul li {
  font-size: 16px;
}

.main-content {
  position: relative;
  transition: all 0.2s ease;
  z-index: 4;
  padding: 8rem 0;
}

.ms-theme .ms-theme-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--background);
  transition: all 0.2s ease-in-out;
  background-size: cover;
  background-position: center;
  filter: grayscale(1);
}

.ms-theme .ms-theme-gradient {
  position: relative;
}

.ms-theme .ms-theme-gradient::after {
  content: "";
  opacity: 0.99;
  position: fixed;
  left: 0;
  top: 0;
  width: 200vw;
  height: 200vh;
  z-index: -1;
  /* animation: move-background 10s linear infinite; */
  background: var(--background);
  /* fallback for old browsers */
}

.fileContainer .uploadPictureContainer {
  padding: 0 !important;
}

.fileContainer .uploadPictureContainer>.uploadPicturesWrapper>div {
  justify-content: flex-start;
}

.fileContainer .uploadPictureContainer {
  border: none;
  box-shadow: none;
  border-radius: 0 !important;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  border-radius: var(--radius);
  height: auto !important;
  width: 100% !important;
}

.fileUploader .fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: var(--white);
  background: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.overflow-blur {
  opacity: 0.05;
  filter: grayscale(1);
}

@keyframes move-background {
  0% {
    transform: translateX(0%) translateY(0%);
  }

  25% {
    transform: translateX(-100vw) translateY(0%);
  }

  50% {
    transform: translateX(-100vw) translateY(-100vh);
  }

  75% {
    transform: translateX(0%) translateY(-100vh);
  }

  100% {
    transform: translateX(0%) translateY(0%);
  }
}

.hide {
  animation: hide 0.3s forwards;
}

.invisible {
  display: none;
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.normal-pad {
  /* padding: 0rem 10rem; */
}

.block-links {
  display: flex;
  justify-content: flex-start;
}

.block-links a {
  display: inline-block;
  margin-right: 1rem;
}

.block-links a:last-child {
  margin-right: 0;
}

.smooth,
.smooth * {
  transition: all 0.2s ease-out;
}

.container {
  position: relative;
  padding: 0 !important;
}

/* .better-container {
  height: 50vw;
  position: relative;
  z-index: 1;
  position: fixed !important;
  left: 0;
  width: 100%;
  top: 0;
} */

.hide-on-desk {
  display: none !important;
}

ul {
  display: inline-block;
  text-align: left;
  margin-left: 15px;
  cursor: default;
}

ul li {
  margin: 6px 0;
}

.long-text {
  text-align: left;
  padding: 0;
}

.long-text h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.cart-product {
  display: inline-block;
  border: 1px solid var(--border-color);
  padding: 2rem 2rem 0 2rem;
  margin: 0 0 5rem 0;
  border-radius: var(--radius);
}

@-webkit-keyframes shine {

  0%,
  10% {
    background-position: -1000px;
  }

  20% {
    background-position: top left;
  }

  90% {
    background-position: top right;
  }

  100% {
    background-position: 1000px;
  }
}

.disappear {
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease;
}

.appear {
  transition: all 0.5s ease;
  transform: scale(1);
  opacity: 1;
}

.MuiStep-horizontal:first-child {
  padding-left: 0 !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-size: 2.4rem;
  color: var(--font-color) !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active {
  color: var(--header-color) !important;
}

.Mui-completed.MuiStepIcon-root {
  color: var(--theme-color) !important;
}

.MuiStepLabel-iconContainer .MuiStepIcon-text {
  font-size: 2rem;
}

svg.Mui-active {
  fill: var(--header-color);
}

.theme-dark img[data-svg='true'] {
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  p {
    line-height: 1.5;
    color: var(--font-color);
  }

  :root {
    /* font-size: 8px; */
  }

  .default {
    --animation-time: 0.6s;
  }
}

@media only screen and (min-width: 1920px) {
  :root {
    /* font-size: 10px; */
  }
}

@media only screen and (min-width: 1600px) {
  :root {
    /* font-size: 10px; */
  }
}

@media only screen and (min-width: 1270px) {
  :root {
    /* font-size: 6px; */
  }
}

@media only screen and (max-width: 480px) {
  :root {
    /* font-size: 6px; */
  }
}

@media screen and (max-width: 700px) {
  .hide-on-phone {
    display: none;
  }

  /* html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  } */

  .normal-pad {
    padding: 0rem 3rem;
  }

  body {
    padding: 1rem 2rem;
    padding-bottom: 22rem;
    padding-top: 9rem;
  }
}