.center-content {
  display: flex;
  justify-content: center;
}

.logo-img {
  width: 100%;
  height: 100%;
}

.profile-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
}

.description-text {
  font-size: 15px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ad-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
  border-radius: var(--radius);
}

.profile-img.small-size {
  width: 50px;
  height: 50px;
}

.description-text.small-size {
  font-size: 14px;
}

/* .ad-img.small-size {
  width: 100px; 
  height: 100px;
} */

.image-container.small-size {
  max-width: 100%;
}

.ad-banner-id {
  font-size: 1.5rem;
}