.css-t3ipsp-control {
    border: none !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover {
    border-color: none !important;
    border: none !important;
}

.css-13cymwt-control {
    border-style: hidden !important;
    border-width: none !important;
    box-sizing: none !important;
    border-radius: var(--radius) !important;
    height: 7rem !important;
}

.css-1p3m7a8-multiValue {
    background-color: var(--background) !important;
    padding: 2px 4px !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    color: var(--header-color) !important;
}

.pending-icon {
    background-color: #E69A28;
    color: var(--foreground);
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 10px;
}

.approve-icon {
    background-color: #008000;
    color: var(--foreground);
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 10px;
}