.pwa-installer-footer .card-v2 {
  padding: 3rem 4rem;
  background-color: var(--theme-color);
  border-radius: var(--radius);
}
@keyframes move-up {
  from {
    opacity: 0;
    transform: translateY(200%);
  }
  to {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%);
  }
}

.pwa-drawer-hidden,
.pwa-footer-hidden {
  opacity: 0;
  display: none;
}
.pwa-installer-footer img {
  border-radius: 50%;
}
.pwa-installer-footer .card-v2 h1 {
  color: var(--background);
  margin: 0;
}
.pwa-installer-footer .card-v2 button {
  margin-top: 3rem;
  padding: 1.5rem 3rem;
  border-radius: var(--radius);
  box-shadow: none;
  border: none;
  color: var(--theme-color);
  background-color: var(--background);
  font-weight: bold;
}

.pwa-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  border-radius: var(--radius);
  z-index: 200;
  opacity: 0;
  transition: var(--transition);
  transform: translateY(200%);
  animation: move-up 0.5s ease 1 forwards 6s;
}
.pwa-mobile .card-v2 {
  border-radius: var(--radius);
}
.pwa-mobile p {
  color: var(--background);
}
.pwa-mobile .card-v2 button {
  padding: 1rem 2rem;
}
.pwa-installer-footer .card-v2 .text-btn {
  margin-right: 1rem;
  background-color: transparent;
  color: var(--background);
}
.pwa-mobile-hidden {
  animation: move-up 0.2s ease 1 forwards reverse;
}
