.checkoutProduct {
  display: flex;
  justify-content: flex-start;
  color: var(--font-color);
  background-color: var(--foreground);
  align-items: center;
  position: relative;
  /* justify-content: center; */
  align-items: center;
  border-radius: var(--radius);
  padding: 2rem;
  margin-bottom: 3rem;
}
.checkoutProduct .product-type {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.checkoutProduct:last-child {
  border-bottom: none;
}
.MuiStepLabel-labelContainer {
  white-space: nowrap;
}

.checkoutProduct .delete {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 7rem;
  height: 7rem;
  border: none;
  /* font-size: 8rem; */
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}
.checkoutProduct .delete:hover {
  color: var(--background);
  background-color: var(--theme-color);
}
.checkoutProduct .delete * {
  color: var(--font-color);
}

.checkoutProduct .delete:hover * {
  color: var(--background);
}
.checkoutProduct__info {
  padding-left: 2rem;
}

.quantity-options {
  width: auto;
  padding: 3px;
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--radius);
  border: 1px solid #ddd;
  color: var(--font-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  margin-top: 0rem;
  color: var(--theme-color);
}

.quantity-options button {
  font-size: 4rem;
  padding-right: 1.4rem;
  padding-left: 1.4rem;
  border: none;
  cursor: pointer;
  opacity: 1;
  background-color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  background: var(--theme-color);
  color: var(--foreground);
  height: 4rem;
  border-radius: var(--radius);
}
.quantity-options button:first-child {
  margin-right: 2rem;
}
.quantity-options button:last-child {
  margin-left: 2rem;
}
.quantity-options button .fas {
  font-size: 1.5rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  color: var(--foreground);
}

.quantity-options .fas:hover {
  /* color: var(--theme-color); */
  opacity: 1;
}

.checkoutProduct__image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 20rem;
  min-height: 20rem;
  border-radius: var(--small-radius);

  /* height: 180px; */
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title {
  font-size: 3rem;
  width: 100%;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.checkoutProduct__price {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--theme-color);
}

.btn-text {
  background: transparent;
  color: var(--button-font-color);
  border: none;
  margin: 1rem 0;
  padding-top: 1rem 0;
  cursor: pointer;
}

.checkoutProduct__image_profile {
  /* width: 20rem; */
  min-height: 16rem;
}
.checkoutProduct.from-profile {
  border: 1px solid var(--border-color);
  height: 100%;
}

.checkoutProduct__brand {
  font-size: 1.6rem;
}
.checkoutProduct__title {
  font-size: 2.3rem;
  margin: 0.5rem 0;
  margin-bottom: 0.5rem;
  color: var(--theme-color);
  font-weight: bold;
}

.checkoutProduct__extradetails {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 766px) {
  .checkoutProduct {
    margin: 5rem 0;
  }
  .checkoutProduct__info {
    padding-left: 0rem;
  }
}
