.vip-chip {
  background-color: var(--foreground);
  border-radius: 30px;
  padding: 4px 10px !important;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
}

.vip-chip.accent {
  background-color: var(--background);
  height: 4rem;
  width: 100%;
  max-width: 60px;
  font-size: 1.5rem;
}

.unread-count {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: var(--error);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.message-card {
  background-color: var(--background);
  padding: 10px;
  margin-bottom: 1px;
  cursor: pointer;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-info {
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-actions {
  display: flex;
  text-align: center;
}

.message-chevron {
  font-size: 15px;
  cursor: pointer;
}

.message-card.selected {
  background-color: var(--foreground-color);
}

.message-list-container {
  border: 0.2px solid var(--border-color);
  padding: 0;
}

.message-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 0.2px solid var(--border-color);
  border-bottom: 0.2px solid var(--border-color);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.message-input-container-children {
  display: flex;
  align-items: center;
  width: 100%;
}

.message-input-container-subchildren {
  border: 1px solid var(--border-color);
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  margin-right: 10px;
}

.chat-messages {
  position: absolute;
  bottom: 60px;
  width: 100%;
  padding: 0px 10px;
  padding-bottom: 50px;
}

.message-input {
  flex: 1;
  border-radius: 4px;
  margin-right: 10px;
  background-color: var(--foreground);
  border: none;
  color: var(--font-color);
}

.chat-messages {
  max-height: 64vh;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
}

.message-item {
  display: flex;
  margin-bottom: 10px;
}

.message-item-right {
  justify-content: flex-end;
}

.chat-message-text {
  background-color: var(--background);
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  margin-left: 10px;
}

.chat-message-text small {
  float: right;
  margin-top: 5px;
}

.user-message-text {
  margin-right: 10px;
}

.tag-class {
  background-color: var(--green);
  padding: 3px 10px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  color: var(--white);
}

.emoji-picker {
  position: absolute;
  bottom: 70px;
  right: 7px;
}

.new-chat-container {
  height: 50px;
  width: 50px;
  background-color: var(--theme-color);
  color: var(--foreground);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}