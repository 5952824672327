.dialog {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  z-index: 101;
  left: 0;
  display: none;
  justify-content: center;
  transition: all 0.5s ease;
  align-items: flex-end;
  /* opacity: 0; */
}

.dialog .dialog-underlay {
  position: absolute;
  left: -10%;
  top: 0;
  width: 120%;
  height: 100%;
  background: var(--overlay-color);
  animation: dialog-animation 0.5s ease forwards;
}

@keyframes dialog-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dialog.open {
  display: flex;
}

.dialog-content>button.dialog-close {
  font-size: 2.5rem;
  border: transparent;
  color: var(--font-color);
  background-color: var(--background);
  position: absolute;
  /* bottom: 0; */
  right: 3rem;
  cursor: pointer;
  /* opacity: 0.3; */
  top: 3rem;
  box-shadow: none;
  background: transparent;
  /* -webkit-transform: translateX(-70%) translateY(-70%); */
  transform: translateX(0) translateY(0);
  width: 6rem;
  height: 6rem;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  z-index: 12;
  transition: var(--small-transition);
}

.dialog-content>button:hover {
  border: 1px solid var(--font-color);
}

.dialog.open>.dialog-content {
  width: 100%;
  max-width: 1024px;
  height: auto;
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 4rem;
  padding-top: 4rem;
  background-color: var(--background);
  position: relative;
  z-index: 4;
  margin-top: 0rem;
  border-radius: var(--radius) var(--radius) 0 0;
  box-shadow: var(--dialog-shadow);
  animation: slide-from-bottom 0.5s ease forwards;
  transform-origin: center;
  transform-style: preserve-3d;
}

.dialog-content .dialog-header {
  position: sticky;
  top: 0;
  padding-top: 3rem;
  padding-bottom: 1rem;
  z-index: 10;
  margin-bottom: 2rem;
  background-color: var(--background);
}

.right .dialog-content .dialog-header {
  padding-top: 1rem;
  top: -4rem;
}

.dialog-content .dialog-action {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 10rem;
  border-top: 1px solid var(--border-color);
  background-color: var(--foreground);
  padding: 3rem;
  padding-bottom: 1rem;
}

.dialog.right {
  justify-content: flex-end;
  top: 0;
  bottom: unset;
}

.dialog.right>.dialog-content {
  width: 30vw;
  height: 102vh;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: var(--radius) 0 0 var(--radius);
  animation: slide-from-right 0.5s ease forwards;

  padding: 4rem;
}

@keyframes slide-from-bottom {
  0% {
    transform: translateY(60%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.dialog.big.open>.dialog-content {
  width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  margin-bottom: 7rem;
  overflow-y: auto;
  padding: 4rem;
  background-color: var(--background);
  position: relative;
  z-index: 4;
  margin-top: 0rem;
  box-shadow: 0;
  border-radius: var(--radius);
  animation: slide-from-bottom 0.2s ease-out forwards;
}

.dialog.pop-up.open>.dialog-content {
  width: 100%;
  max-width: 500px;
  min-height: 60vh;
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 30rem;
  padding: 4rem;
  background-color: var(--background);
  position: relative;
  z-index: 4;
  box-shadow: 0;
  border-radius: var(--radius);
  animation: slide-from-bottom 0.2s ease-out forwards;
}

.right .dialog-content>button {
  position: absolute;
  right: 2rem;
  top: 3rem;
  font-size: 2.5rem;
  background: var(--foreground);
  width: 6rem;
  height: 6rem;
  color: var(--font-color);
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .dialog {
    width: 95% !important;
    left: 2.5% !important;
  }

  /* .dialog.big.open > .dialog-content {
    min-height: 94vh;
    max-height: 94vh;
  } */
  .dialog.big.open>.dialog-content {
    padding: 10% 3rem 0rem;
  }

  .dialog.open>.dialog-content {
    width: calc(80%);
    margin-top: 0;
    bottom: 0;
    padding: 3rem;

    padding-bottom: 5rem;
  }

  .dialog-content>button {
    position: absolute !important;
    /* bottom: 0; */
    right: 3rem !important;
    top: 2rem !important;
    font-size: 3rem !important;
    box-shadow: none;
    background: transparent;
    /* -webkit-transform: translateX(-70%) translateY(-70%); */
    transform: translateX(0) translateY(0);
  }
}

@media only screen and (max-width: 400px) {

  .dialog.open>.dialog-content,
  .dialog.big.open>.dialog-content {
    width: 100%;
    margin-top: 0;
    bottom: 0;
    padding: 3rem;
    border-radius: var(--radius);
    padding-bottom: 3rem;
    max-height: 100%;
    padding-top: 5rem;
    overflow-y: auto;
  }

  .dialog.big.open .dialog-content>button {
    right: 2rem;
    top: 1rem;
    font-size: 3rem;
  }

  .dialog.big.open>.dialog-content {
    padding: 10% 2rem 0rem;
    width: 100%;
  }
}