.icon-text {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
}

.icon-circle {
  background-color: var(--background);
  color: var(--theme-color);
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle .fa-icon {
  font-size: 1.2em;
}