.primary-menu .create-store-card {
 transform: translateX(100%);
 transition: all 0.0s;
 height: 0px;
 overflow: hidden;
}

.primary-menu-active .create-store-card {
 transform: translateX(0%);
 transition: all 0.5s;
 height: auto;
 overflow: visible;
}