.product-rater {
}

.product-rater .product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius);
}

/* .display-review {
    padding: 2rem 2rem;
    border: 1px solid var(--border-color);
    margin: 1rem 0;
    border-radius: var(--radius);
} */

.display-review h1 {
  margin-top: 0 !important;
}
